import React from 'react';
import './App.css';

function App() {
    return (
        <div>
            <header className="header">
                <h1 className="title">
                    Docta
                </h1>
                <p className="text">
                    Hier bouwt Docta aan de toekomst van het onderwijs.
                    We zijn nog druk bezig met deze site, kom later een keer terug!
                </p>
            </header>
        </div>
    );
}

export default App;
